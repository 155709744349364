import { IBEPax, TripDetail } from "@hkexpressairwayslimited/ui";
import { formatISO } from "date-fns";
import {
  CreateOrderResponse,
  CreatePaymentApiProps,
  GetOrderStatusResponse,
} from "lib/features/flight-book/payment/definition";
import { isCCMethod } from "lib/features/flight-book/payment/helper";
import {
  CCEncryptionToken,
  createCCpayment,
  createPayment,
  creditDataExchange,
  encryptWithJwk,
  generateSignature,
  getCCEncryptionToken,
  getOrderStatus,
} from "lib/features/flight-book/payment/service";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { InitMMBChangeFlightPaymentSaga } from "lib/features/manage-my-booking/my-trips/payment/definition";
import { constructMMBOrderCreateBody } from "lib/features/manage-my-booking/my-trips/payment/helper";
import { changeFlightOrderCreation } from "lib/features/manage-my-booking/my-trips/service";
import {
  closeLoadingBackdrop,
  showLoadingBackdrop,
} from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { Effect, call, cancel, delay, fork, put, take, takeLatest } from "redux-saga/effects";
import { RouterInstance } from "router/router-utils";
import { storeCCPaymentMetaData } from "store/sessionStorage/slices/ccPaymentMetaData";
import {
  ChangeFlightJourneyDate,
  Fees,
  updateChangeFlightAnalyticPaymentDetail,
} from "store/sessionStorage/slices/manageMyBookingSlice";
import { v4 } from "uuid";
import { INIT_MMB_CHANGE_FLIGHT_PAYMENT_SAGA, createMMBFormAction } from "../actions";

function* createOrderId(
  mileAmount = 0,
  cash_amount = 0,
  currency: string,
  tripCost: number,
  booking: ManageMyBookingTripDetail,
  changeFlightTrip: TripDetail & {
    passengers: IBEPax[];
    notTransferSSR: any[];
    fees: Fees;
    changeFlightJourneyDate: {
      first_journey?: ChangeFlightJourneyDate;
      second_journey?: ChangeFlightJourneyDate;
    };
  },
  insurance?: number,
  carbon_offset?: number
): Generator<Effect, void, any> {
  try {
    const requestBody = yield call(constructMMBOrderCreateBody, {
      booking,
      mileAmount,
      cash_amount,
      currency,
      tripCost,
      changeFlightTrip,
      insurance,
      carbon_offset,
    });

    const { order_id, order_type }: CreateOrderResponse = yield call(changeFlightOrderCreation, requestBody);
    if (order_id) {
      yield put({ type: "CREATE_PAYMENT_SAGA", order_id, order_type });
    } else {
      yield put({ type: "CANCEL_PAYMENT_SAGA" });
    }
  } catch (e) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
  }
}

function* initCCpayment(
  { creditCardDetail, paymentMethod }: InitMMBChangeFlightPaymentSaga,
  order_id: string
): Generator<Effect, void, any> {
  if (!creditCardDetail) return;
  const dataExchangeResp = yield call(creditDataExchange, {
    AccountNumber: creditCardDetail.cardNumber.replace(/\s/g, ""),
  });

  if (dataExchangeResp.jwt) {
    yield put(storeCCPaymentMetaData({ creditDataExchange: dataExchangeResp }));
  }
}

function* createCCpaymentSaga(
  { creditCardDetail, paymentMethod, convenience_fee, dcc_key, dcc_accept_offer }: InitMMBChangeFlightPaymentSaga,
  order_id: string,
  tds_md: string,
  afy_tid: string
): Generator<Effect, void, any> {
  if (!creditCardDetail) return;
  const ccToken: CCEncryptionToken = yield call(getCCEncryptionToken);
  const { cardNumber, expiryDate, cardHolderName, cv2Number } = creditCardDetail;
  const nonce = v4();
  const timestamp = formatISO(new Date());
  const CCPaymentRequestBody = {
    order_id: order_id,
    device_type: "WEB",
    locale: "en_hk",
    success_url: "http://localhost:8080/success",
    failure_url: "http://localhost:8080/failure",
    payment_option: paymentMethod,
    convenience_fee,
    cc_payload: {
      dcc_key,
      dcc_accept_offer,
      card_number: encryptWithJwk(ccToken, cardNumber.replace(/\s/g, "")),
      card_expiry: encryptWithJwk(ccToken, expiryDate),
      card_cvc: encryptWithJwk(ccToken, cv2Number),
      card_holder_name: cardHolderName,
      nonce: nonce,
      timestamp: timestamp,
      sig: generateSignature(cardNumber.replace(/\s/g, ""), expiryDate, cv2Number, nonce, timestamp),
      tds_md,
      afy_tid,
    },
  };
  const rest = yield call(createCCpayment, CCPaymentRequestBody);
  if (rest.txn_id) {
    // yield call(retrieveOrderStatus, order_id);
    yield put({ type: "RETRIEVE_ORDER_STATUS" });
  } else {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
  }
  return rest;
}

function* createPaymentSaga(
  order_id: string,
  { paymentMethod, convenience_fee = 0 }: InitMMBChangeFlightPaymentSaga
): Generator<Effect, void, any> {
  if (!paymentMethod) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    return;
  }
  const rest = yield call(createPayment, {
    order_id: order_id,
    device_type: "WEB",
    locale: "en_hk",
    success_url: "http://localhost:3000/en/confirmation",
    failure_url: "http://localhost:3000/en/confirmation",
    payment_option: paymentMethod,
    convenience_fee,
  } as CreatePaymentApiProps);
  if (rest.txn_id) {
    // yield call(retrieveOrderStatus, order_id);
    yield put({ type: "RETRIEVE_ORDER_STATUS" });
  } else {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
  }
  return rest;
}

function* retrieveOrderStatus(order_id: string, booking: ManageMyBookingTripDetail): Generator<Effect, void, any> {
  while (true) {
    const response: GetOrderStatusResponse = yield call(getOrderStatus, order_id);
    if (response.order_status === "F_COMP") {
      yield put(createMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id }));
      RouterInstance.push(`/:lang/manage-my-booking/my-trips/${booking.sales_reference}/change-flight`);
      yield put({ type: "CANCEL_PAYMENT_SAGA" });
      break;
    } else if (response.order_status === "S_COMP") {
      yield put(createMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id }));
      // yield put(storeTripDetailSalesReference(response.sales_reference));
      RouterInstance.push("/:lang/confirmation?" + new URLSearchParams({ typeFlow: "MMB_CHANGE_FLIGHT" }));
      yield put({ type: "CANCEL_PAYMENT_SAGA" });
      break;
    } else if (response.order_status === "PEND") {
      yield put(createMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id }));
    }
    yield delay(5000);
  }
}
function* initPaymentSaga(action: any): Generator<Effect, void, any> {
  yield put(showLoadingBackdrop());
  const {
    booking,
    changeFlightTrip,
    cash_amount,
    mileAmount,
    paymentMethod,
    currency,
    tripCost,
    insurance,
    carbon_offset,
  } = action.payload as InitMMBChangeFlightPaymentSaga;
  const createOrderTask = yield fork(
    createOrderId,
    mileAmount,
    cash_amount,
    currency,
    tripCost,
    booking,
    changeFlightTrip,
    insurance,
    carbon_offset
  );

  const { order_id, order_type } = yield take("CREATE_PAYMENT_SAGA");
  yield put(
    updateChangeFlightAnalyticPaymentDetail({
      confirmation_code: order_id,
    })
  );
  yield cancel(createOrderTask);

  let createPaymentTask: any;
  let orderStatusTask = undefined;
  if (order_type === "NO_PAYMENT_ORDER") {
    orderStatusTask = yield fork(retrieveOrderStatus, order_id, booking);
  } else if (isCCMethod(paymentMethod)) {
    yield put(
      storeCCPaymentMetaData({
        orderNumber: order_id,
        amount: cash_amount,
      })
    );
    createPaymentTask = yield fork(initCCpayment, action.payload, order_id);
    const { md, afy_tid } = yield take("CREATE_CC_PAYMENT");
    yield fork(createCCpaymentSaga, action.payload, order_id, md, afy_tid);
  } else {
    createPaymentTask = yield fork(createPaymentSaga, order_id, action.payload);
  }

  if (!orderStatusTask) {
    yield take("RETRIEVE_ORDER_STATUS");
    orderStatusTask = yield fork(retrieveOrderStatus, order_id, booking);
  }

  yield take("CANCEL_PAYMENT_SAGA");
  yield put(closeLoadingBackdrop());
  yield cancel(createOrderTask);
  yield cancel(createPaymentTask);
  yield cancel(orderStatusTask);
}

export function* mmbChangeFlightPaymentSaga() {
  yield takeLatest(INIT_MMB_CHANGE_FLIGHT_PAYMENT_SAGA, initPaymentSaga);
}
