import { ApiRequestInit, endpoints, fetchAPI } from "api";
import { FlightRouteMapping } from "store/sessionStorage/slices/flightRouteMapping";

export const getFlightRoute = async (): Promise<FlightRouteMapping> => {
  const result = await fetchAPI<FlightRouteMapping>(endpoints.flightRouteMapping, {
    method: "GET",
    cache: "no-store",
    headers: { "Content-Type": "application/json" },
  });
  return result;
};
export type MetaData<MetaDataType extends MetaDataTypes | undefined = undefined> = Omit<
  {
    create_date_time: string;
    update_date_time: string;
    version: number;
    id: number;
    metadata_key: string;
    metadata_type: string;
    metadata_value: any;
  },
  keyof MetaDataTypePayloadMapping<MetaDataType>
> &
  MetaDataTypePayloadMapping<MetaDataType>;
export type GlobalMetaDataResp<MetaDataType extends MetaDataTypes | undefined = undefined> = {
  content: MetaData<MetaDataType>[];
};
export enum MetaDataTypes {
  HCAPTCHA_SETTING = "HCAPTCHA_SETTING",
  MANDATORY_SIGNIN = "MANDATORY_SIGNIN",
}
export type MetaDataTypePayloadMapping<MetaDataType extends MetaDataTypes | undefined> =
  MetaDataType extends MetaDataTypes.HCAPTCHA_SETTING
    ?
        | {
            metadata_key: "IS_ENABLE";
            metadata_type: "HCAPTCHA_SETTING";
            metadata_value: "true" | "false";
          }
        | { metadata_key: "FAULT_SCORE"; metadata_type: "HCAPTCHA_SETTING"; metadata_value: string }
    : {};
export const getMetaData = async <MetaDataType extends MetaDataTypes | undefined = undefined>(
  metadata_type: MetaDataTypes,
  options: ApiRequestInit = {}
) => {
  const result = await fetchAPI<GlobalMetaDataResp<MetaDataType>>(
    endpoints.adminHost + `/public/v1/system-metadata?metadata_type=${metadata_type}`,
    {
      ...options,
      method: "GET",
      cache: "no-store",
      headers: { "Content-Type": "application/json" },
    }
  );
  return result;
};
export const getHCaptchaSetting = async () => {
  const res = await getMetaData<typeof MetaDataTypes.HCAPTCHA_SETTING>(MetaDataTypes.HCAPTCHA_SETTING, {
    showBackDrop: false, // silently load hCaptcha setting
  });

  return {
    isEnable: res.content.find((item) => item.metadata_key === "IS_ENABLE")?.metadata_value === "true",
  };
};
