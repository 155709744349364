const languageList = process.env.NEXT_PUBLIC_LANGUAGE_LIST?.split(",") || [];

export const envConfig = {
  disableSearchIndexing: process.env.NEXT_PUBLIC_DISABLE_SEARCH_INDEXING === "true",
  hCaptchaSiteKey: process.env.NEXT_PUBLIC_HCAPTCHA_SITE_KEY,
  qualtricsUrl: process.env.NEXT_PUBLIC_QUALTRICS_URL,
  publicUrl: process.env.NEXT_PUBLIC_PUBLIC_URL || "",
  bookingHost: process.env.NEXT_PUBLIC_BOOKING_HOST || "",

  defaultCurrency: process.env.NEXT_PUBLIC_DEFAULT_CURRENCY || "",
  languageList,
  defaultLanguage: languageList.find((lang) => lang === process.env.NEXT_PUBLIC_DEFAULT_LANGUAGE) || "",

  manageDomain: process.env.NEXT_PUBLIC_API_ADMIN_DOMAIN,
  apiDomain: process.env.NEXT_PUBLIC_API_DOMAIN || "",
  cognitoDomain: process.env.NEXT_PUBLIC_COGNITO_DOMAIN || "",
  cognitoClientId: process.env.NEXT_PUBLIC_COGNITO_CLIENT_ID || "",
  endpointAdminHost: `${process.env.NEXT_PUBLIC_API_ADMIN_DOMAIN || ""}/admin`,
  endPointAuthHost: `${process.env.NEXT_PUBLIC_API_DOMAIN || ""}/auth`,
  endPointFlightBookingManagementHost: `${process.env.NEXT_PUBLIC_API_DOMAIN || ""}/flt-booking-mgmt`,
  endPointFlightBookingQueryHost: `${process.env.NEXT_PUBLIC_API_DOMAIN || ""}/flt-booking-query`,
  endpointMemberHost: `${process.env.NEXT_PUBLIC_API_DOMAIN || ""}/member`,
  endpointOrderHost: `${process.env.NEXT_PUBLIC_API_ADMIN_DOMAIN || ""}/order`,

  endpointPaymentHost: process.env.NEXT_PUBLIC_PAYMENT_API_DOMAIN || "",

  endpointContent: process.env.NEXT_PUBLIC_API_DOMAIN + "/non-pss-int/public/v1/content-management/list",
  endpointVoucher: process.env.NEXT_PUBLIC_API_DOMAIN + "/flt-booking-mgmt/v1/nsk/booking/voucher",

  enableAccertify: process.env.NEXT_PUBLIC_ENABLE_ACCERTIFY === "true",
  enableMockService: process.env.NEXT_PUBLIC_ENABLE_MOCK_SERVICE === "true",
  mockAccessToken: "",

  loginUrl: process.env.NEXT_PUBLIC_LOGIN_URL || "", // might not be relevant anymore
  adobeAnalyticScript: process.env.NEXT_PUBLIC_ADOBE_ANALYTIC_SCRIPT,
};
