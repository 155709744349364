import {
  Button,
  ButtonVariant,
  Card,
  CardContent,
  ChevronLeftIcon,
  CrossOutlineIcon,
  EFont,
  ESpacing,
  Font,
  Drawer as HKEDrawer,
  TextSize,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui";
import { Box, Stack, SvgIcon } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
type DrawerProps = {
  title: string | JSX.Element | JSX.Element[];
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel?: string | JSX.Element | JSX.Element[];
  children?: JSX.Element | JSX.Element[] | string | number;
  avatar: JSX.Element;
  totalCost: string | JSX.Element;
  crossIconClose?: boolean;
  autoClose?: boolean;
  backButton?: boolean;
  backButtonOnClick?: () => void;
};
export function Drawer({
  open,
  onClose,
  title,
  children,
  avatar,
  totalCost,
  onConfirm,
  confirmLabel,
  crossIconClose = true,
  autoClose = false,
  backButton = false,
  backButtonOnClick,
}: DrawerProps) {
  const { t } = useTransContent();
  const { isMobile } = useMediaQueries();
  return (
    <HKEDrawer
      open={open}
      onClose={() => {
        autoClose && onClose();
      }}
    >
      <Box sx={{ minHeight: "100%", width: "40.5rem" }}>
        <Stack direction='row' alignItems='center' justifyContent='space-between'>
          <Stack
            direction='row'
            alignItems='center'
            justifyContent={crossIconClose ? "space-between" : "flex-start"}
            spacing={ESpacing._s}
          >
            {!backButton ? (
              <SvgIcon sx={{ color: "purple.default" }} fontSize='large'>
                {avatar}
              </SvgIcon>
            ) : (
              <SvgIcon fontSize='large' onClick={backButtonOnClick}>
                <ChevronLeftIcon />
              </SvgIcon>
            )}
            <Font variant={!isMobile ? EFont.h1 : EFont.h3} fontWeight={isMobile ? "bolder" : "fontWeightBold"}>
              {title}
            </Font>
          </Stack>
          {crossIconClose && (
            <SvgIcon onClick={onClose} sx={{ color: "neutral.black", fontSize: ESpacing._xs }}>
              <CrossOutlineIcon />
            </SvgIcon>
          )}
        </Stack>
        <Stack sx={{ minHeight: "100%", paddingBottom: ESpacing._3xl }} justifyContent='space-between'>
          <Box mt={ESpacing._sm} display={"flex"} justifyContent={"center"}>
            {children}
          </Box>
          <Card
            borderless
            withBGC
            sx={{
              marginTop: ESpacing._lg,
              position: "fixed",
              width: isMobile ? "calc(100% - 24px)" : "calc(46.5rem - 24px)",
              bottom: 12,
              right: 12,
            }}
          >
            <CardContent>
              <Stack direction='row' justifyContent='space-between'>
                <Stack
                  direction={isMobile ? "column" : "row"}
                  justifyContent='space-between'
                  alignItems={isMobile ? "flex-start" : "center"}
                  spacing={ESpacing._3xs}
                >
                  <Font variant={isMobile ? EFont.p2 : undefined}>{t("web.addExtras.baggage.subtotal")}</Font>
                  <Font variant={isMobile ? EFont.p1 : EFont.h3} fontWeight='fontWeightBold'>
                    {totalCost}
                  </Font>
                </Stack>
                <Button
                  variant={ButtonVariant.Secondary}
                  onClick={onConfirm}
                  size={isMobile ? TextSize.P2Medium : undefined}
                >
                  {confirmLabel}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        </Stack>
      </Box>
    </HKEDrawer>
  );
}
