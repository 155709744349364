import { Box, Card, CardContent, EFont, ESpacing, Font, Tag, useMediaQueries } from "@hkexpressairwayslimited/ui";
import { Stack } from "@mui/material";
import { useTransContent } from "modules/common/trans-content/transContent";
import { useCurrency } from "modules/global/currency/useCurrency";
import { EBaggageArCode, SelectableArItem } from "..";

export function BaggageItem({
  arItem,
  active,
  disabled,
  onBaggageSelect,
  purchased,
  displayTag,
}: {
  arItem: SelectableArItem;
  active: boolean;
  disabled: boolean;
  purchased: boolean;
  displayTag: boolean;
  onBaggageSelect: (arItem: SelectableArItem, active: boolean) => void;
}) {
  const { P } = useCurrency();
  const included = !!arItem.arMetaData?.included;
  const { t } = useTransContent();
  const { isMobile } = useMediaQueries();
  return (
    <>
      <Card
        onClick={() =>
          (!disabled || active) && !purchased && !included && onBaggageSelect && onBaggageSelect(arItem, active)
        }
        size='s'
        withBGC={active && !included ? "primary-light" : undefined}
        bodC={active && !included ? "active" : disabled ? "disabled" : "default"}
        sx={{
          position: "relative",
          overflow: "initial !important",
        }}
      >
        <CardContent sx={{ height: "100%" }}>
          <Box
            sx={{
              position: "absolute",
              top: `-${ESpacing._s}`,
              display: displayTag ? "block" : "none",
              left: ["12%", "12%", "15%"],
            }}
          >
            <Tag
              value={t("web.addExtras.baggage.tags")}
              variant='promo_tag'
              sx={{
                padding: [`0px ${ESpacing._2xs} !important`, `0px ${ESpacing._2xs} !important`, `0px !important`],
                fontSize: [EFont.p3, EFont.p3, EFont.p2],
              }}
            ></Tag>
          </Box>
          <Stack
            alignItems='center'
            sx={{ minHeight: "72px", minWidth: ["69px", "69px", "80px"], cursor: "pointer" }}
            role='button'
            justifyContent={
              (active === true && arItem.code === EBaggageArCode._0KG) || purchased || included
                ? "flex-start"
                : "space-between"
            }
          >
            <Font
              fontWeight='fontWeightBold'
              variant={isMobile ? EFont.p3 : EFont.p1}
              color={(!active && disabled) || included ? "neutral.disable" : "neutral.black"}
            >
              {`${arItem.code === EBaggageArCode._0KG || included ? "" : "+"}${t(`${arItem.code}.title.short`)}`}
            </Font>
            {(active === false || arItem.code !== EBaggageArCode._0KG) && (
              <>
                {included && (
                  <Font
                    variant={EFont.p3}
                    color={(!active && disabled) || included ? "neutral.disable" : "neutral.black"}
                  >
                    {included ? t("web.addExtras.baggageSection") : ""}
                  </Font>
                )}
                {purchased && (
                  <Font
                    variant={EFont.p3}
                    color={(!active && disabled) || included ? "neutral.disable" : "neutral.black"}
                  >
                    {purchased && arItem.code !== EBaggageArCode._0KG ? "added" : ""}
                  </Font>
                )}
                {!purchased && !included && (
                  <Font
                    fontWeight='fontWeightBold'
                    variant={isMobile ? EFont.p3 : EFont.p1}
                    color={(!active && disabled) || included ? "neutral.disable" : "purple.default"}
                  >
                    {P(arItem.price ?? 0)}
                  </Font>
                )}
              </>
            )}
          </Stack>
        </CardContent>
      </Card>
    </>
  );
}
