import { VoucherItem } from "@hkexpressairwayslimited/ui";
import { PaymentOptionRenderItem } from "ui/features/flight-booking/payment/payment-method-card/payment-method-card";
import { CCEncryptionToken, DCCQuoteResp } from "./service";

export enum ErrorType {
  "M" = "Message",
  "C" = "Confirm",
  "D" = "Detail",
  "F" = "Function",
}
export type ErrorMessageDetail<T = any, C = any & { trace_id?: string }> = {
  type?: ErrorType;
  title?: string;
  desc?: string;
  image?: string;
  ctaText?: string;
  details?: T[];
  detailsElm?: (details: T[]) => JSX.Element[] | null | undefined;
  onClose?: () => void;
  onConfirm?: () => void;
  fn?: () => void;
  data?: C;
};
export type ErrorMapping = {
  [errorCode: string]: ErrorMessageDetail;
};

export type responseApiError = {
  error_code: string;
  error_message: string;
  error_detail: string;
  metadata?: APIresponseMetadata;
};

export type APICreateOrderRequestBody = {
  sales_channel: string;
  sales_market: string;
  customer_country: string;
  sales_port: string;
  currency_code: string;
  cash_amount: number;
  mile_amount: number;
  infant_total_amount: number;
  application_code?: string;
  promotion_code?: string;
  // uo_username: string;
  trip_type: string;
  with_sms: boolean;
  selected_currency_code: string;
  mcp: {
    currency_code: string;
    total_amount: number;
  } | null;
  journeys: Journey[];
  insurance: {
    item_amount: number;
  } | null;
  carbon_offset: {
    item_amount: number;
  } | null;
  passengers: Passenger[];
  ssrs: SSR[];
  contact_person: ContactPerson;
  // total: Total;
  pay_by_organization_credit?: boolean;
};

export type APICreateOrderValidationRequestBody = {
  // cx_member_number?: string | number;
  // uo_username: string;
  promotion_code?: string;
  market: string;
  trip_type: string;
  selected_currency_code: string;
  currency_code: string;
  customer_country: string; //based on customer phone
  application_code: string;
  infant_total_amount: number;
  journeys: Journey[];
  passengers: Passenger[];
  ssrs: SSR[];
  contact_person: ContactPerson;
};

export type CreatePaymentApiProps = {
  order_id: string;
  device_type: string;
  locale: string;
  success_url: string;
  failure_url: string;
  payment_option: string;
};

export type CreateOrderResponse = {
  order_id: string;
  order_type: string;
};

export type GetOrderStatusResponse = {
  form_action: string;
  form_value: string;
  order_status: string;
  cash_payment_status?: string;
  sales_reference: string;
  payment_error?: {
    error_code: string;
    error_detail: string;
    error_message: string;
  };
  metadata: APIresponseMetadata;
} & Partial<{
  mile_payment_status: string;
  error_message: string;
}>;

export type LongPollingFormAction = {
  form_action: string; //IFRAME or REDIRECT
  form_url: string;
  order_id: string;
};

export type CmsKeys = {
  benefits: {
    adultCmsKey: string;
    nonAdultCmsKey: string | null;
  };
};

export type InsuranceQuote = {
  num_valid_passenger: number;
  total_amount: number;
  amount_per_valid_passenger: number;
  currency_code: string;
  cms_keys: CmsKeys;
  plan_type: string;
};

export type CarbonQuote = {
  currency_code: string;
  total_amount: number;
  total_kilos_co2: number;
};

export type PaymentOption = {
  payment_option: PaymentMethod;
  convenience_fee: number;
  currency_code: string;
};

export type organizationCreditAccountConf = {
  // organizationCreditAccount: AgentAccount;
  paymentOption?: (onclick: () => void, available: boolean, isSelected: boolean) => PaymentOptionRenderItem;
  component?: JSX.Element;
};

export type AgentAccount = {
  account_number: string;
  available_credit: number;
  currency_code: string;
  foreign_available_credit: number;
  foreign_currency_code: string;
};

export type PaymentMetaData = {
  insurance_quote: InsuranceQuote;
  carbon_quote: CarbonQuote;
  payment_option_list: PaymentOption[];
  organization_credit_account?: AgentAccount;
  selected_e_payment_fee?: number;
};

export enum PaymentMethod {
  "Visa" = "VI",
  "MasterCard" = "MC",
  "UnionPay" = "UC",
  "Alipay" = "AP",
  "AlipayHK" = "AH",
  "WechatPay" = "WW",
  "Amex" = "AX",
  "JCB" = "JC",
  "AG" = "AG",
  "OnHold" = "OH",
  "Cash" = "CA",
}

export type CreditCardInfo = {
  cardHolderName: string;
  cardNumber: string;
  cv2Number: string;
  expiryDate: string;
};
export type onHoldCreditInfo = {
  isSelectPaymentMethod?: PaymentMethod;
  readAndAcceptConditions?: boolean;
  confirmAndAcceptPolicies?: boolean;
};
export type PaymentPageDetail = {
  selectedCCpayment?: boolean;
  paymentMethod?: PaymentMethod;
  vouchers?: VoucherItem[];
  mileToCashAmount?: number;
  mileAmount?: number;
  tripCost?: number;
  creditCardDetail?: CreditCardInfo;
  consent?: boolean;
  insurance?: number | undefined;
  carbonOffset?: boolean;
  dccInfo?: DCCQuoteResp;
  withDcc?: boolean;
  dccOption?: string;
  currency?: PaymentMethod;
  onHoldDetail?: onHoldCreditInfo;
};

export type InitPaymentSaga = {
  cash_amount: number;
  infant_total_amount: number;
  mileAmount: number;
  paymentMethod: PaymentMethod;
  currency: string;
  mcp: {
    currency_code: string;
    total_amount: number;
  } | null;
  dcc_key: string | null;
  dcc_accept_offer?: boolean;
  creditCardDetail?: CreditCardInfo;
  convenience_fee: number | string;
  ccToken?: CCEncryptionToken;
  carbon_offset?: number;
  insurance?: number;
  lang?: string;
  repay_order_id?: string;
  repay_order_type?: string;
};

// --------------------------------------------------
type PhoneNumber = {
  country_code: string;
  number: string;
};

type ContactPerson = {
  language: string;
  phone_number: PhoneNumber;
  first_name: string;
  last_name: string;
  email_address: string;
  title: string;
};

export type SSR = {
  ssr_code: string;
  ssr_key: string;
  item_amount: number;
  surcharge: number;
  tax: number;
  quantity: number;
};

type Seat = {
  unit_key: string;
  unit_designator: string;
  item_amount: number;
};

type Passenger = {
  title: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  gender: string;
  passport: string;
  passenger_type: string;
  passenger_key: string;
  seats: Seat[];
  infant?: Infant | undefined;
};

type Infant = {
  title: string;
  first_name: string;
  last_name: string;
  date_of_birth: string;
  gender: string;
};

type Journey = {
  origin_country: string;
  destination_country: string;
  origin_port: string;
  destination_port: string;
  fare_type: string;
  flight_numbers: string[];
  departure_date_time: string;
  arrival_date_time: string;
  journey_key: string;
  fare_availability_key: string;
  item_amount: number;
  surcharge: number;
  tax: number;
  is_first_journey: boolean;
  bundle: Bundle;
};

type Bundle = {
  bundle_code: string;
  adult_price: number;
  child_price: number;
};

type Total = {
  amount: number;
  currency: string;
  label: string;
};

export type AddVoucherRequestBody = {
  reference_code: string;
};

export type DeleteVoucherRequestBody = {
  remaining_voucher_reference_codes: string[];
};

export type CreditDataExchangeRequest = {
  AccountNumber: string;
};

export type CreditDataExchangeResponse = {
  jwt: string;
  device_data_collection_url: string;
  md: string;
};

export type CreditCMPILookupRequest = {
  order_number: string;
  card_number: string;
  card_exp_month: string;
  card_exp_year: string;
  d_f_referenceId: string;
  currency_code: string;
  amount: string;
  email: string;
  transaction_type: string;
  device_channel: string;
};

export type APIresponseMetadata = {
  app_version: string;
  status_code: number;
  trace_id: string;
};

export type CreditCMPILookupResponse = {
  metadata: APIresponseMetadata;
  error_code: string;
  error_message: string;
  error_detail: string;
};
