import { createAction } from "@reduxjs/toolkit";
import { InitPaymentSaga, LongPollingFormAction } from "lib/features/flight-book/payment/definition";

const namespace = "AMPortal";

export const INIT_AM_PORTAL_PAYMENT_SAGA = `${namespace}/INIT_AM_PORTAL_PAYMENT_SAGA`;
export const initAMPortalPaymentSaga = createAction<Partial<InitPaymentSaga>>(INIT_AM_PORTAL_PAYMENT_SAGA);

export const CREATE_AM_PORTAL_FORM_ACTION = `${namespace}/CREATE_AM_PORTAL_FORM_ACTION`;
export const createAMPortalFormAction = createAction<LongPollingFormAction>(CREATE_AM_PORTAL_FORM_ACTION);
