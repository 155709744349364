import { CloseIcon, EPixel, ERadius, ESpacing, SvgIcon, lightTheme, useMobileQuery } from "@hkexpressairwayslimited/ui";
import { Breakpoint, Dialog, DialogContent, IconButton, styled } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { SxProps } from "@mui/system";
import React from "react";

type PopUpModalProps = {
  maxWidth: Breakpoint;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>> | ((open: boolean) => void);
  children: React.ReactNode;
  customHandleClose?: () => void;
  disableClose?: boolean;
  sx?: SxProps<Theme>;
};

const PopUpModal = ({ maxWidth, open, setOpen, children, customHandleClose, disableClose, sx }: PopUpModalProps) => {
  const isMobile = useMobileQuery();

  function handleClose(event: React.MouseEvent) {
    event.stopPropagation();
    setOpen(false);
  }

  const StyledDialog = styled(Dialog)({
    "& .MuiDialog-paper": {
      borderRadius: isMobile ? "16px 16px 0 0" : ERadius.r300,
      overflowY: "auto",
      padding: `${ESpacing._lg} ${ESpacing._sm}`,
      maxWidth: isMobile ? "100%" : "",
      width: isMobile ? "100%" : "calc(100% - 64px)",
      margin: isMobile ? 0 : ESpacing._md,
      alignSelf: isMobile ? "flex-end" : "center",
    },
    "& .MuiDialogContent-root": {
      padding: "1px",
      overflowY: "auto",
    },
  });

  return (
    <StyledDialog
      open={open}
      onClose={customHandleClose ? customHandleClose : disableClose ? undefined : handleClose}
      fullWidth={isMobile ? false : true}
      maxWidth={isMobile ? false : maxWidth}
      sx={{
        ...sx,
        zIndex: 1300,
      }}
    >
      {!disableClose ? (
        <IconButton
          aria-label='close'
          onClick={customHandleClose ? customHandleClose : disableClose ? undefined : handleClose}
          sx={{
            position: "absolute",
            right: 12,
            top: 32,
            color: (theme) => theme.palette.neutral.grey,
          }}
        >
          <SvgIcon sx={{ color: lightTheme.palette.neutral.black, width: EPixel.px32, height: EPixel.px32 }}>
            <CloseIcon />
          </SvgIcon>
        </IconButton>
      ) : null}
      <DialogContent>{children}</DialogContent>
    </StyledDialog>
  );
};

export default PopUpModal;
