import { formatISO } from "date-fns";
import { B2BUnsettledPaymentOrderCreation } from "lib/features/b2b/agent-portal/service";
import {
  CreateOrderResponse,
  CreatePaymentApiProps,
  ErrorType,
  GetOrderStatusResponse,
  PaymentMethod,
} from "lib/features/flight-book/payment/definition";
import { isCCMethod } from "lib/features/flight-book/payment/helper";
import {
  createCCpayment,
  createPayment,
  creditDataExchange,
  encryptWithJwk,
  generateSignature,
  getOrderStatus,
} from "lib/features/flight-book/payment/service";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import { InitMMBPaymentSaga } from "lib/features/manage-my-booking/my-trips/payment/definition";
import { constructUnsettledPaymentOrderCreationBody } from "lib/features/manage-my-booking/my-trips/payment/helper";
import {
  closeLoadingBackdrop,
  showLoadingBackdrop,
} from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import { Effect, call, cancel, delay, fork, put, spawn, take, takeEvery } from "redux-saga/effects";
import { RouterInstance } from "router/router-utils";
import { storeCCPaymentMetaData } from "store/sessionStorage/slices/ccPaymentMetaData";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";
import { v4 } from "uuid";
import { INIT_B2B_MMB_UNSETTLE_PAYMENT_SAGA, createB2BMMBFormAction } from "../actions";

function* createOrderId(
  mileAmount = 0,
  cash_amount = 0,
  currency: string,
  tripCost: number,
  booking: ManageMyBookingTripDetail,
  pay_by_organization_credit: boolean
): Generator<Effect, void, any> {
  try {
    const requestBody = yield call(
      constructUnsettledPaymentOrderCreationBody,
      booking,
      cash_amount,
      pay_by_organization_credit
    );

    const { order_id, order_type }: CreateOrderResponse = yield call(B2BUnsettledPaymentOrderCreation, requestBody);
    if (order_id) {
      yield put({ type: "CREATE_PAYMENT_SAGA", order_id, order_type });
    } else {
      yield put({ type: "CANCEL_PAYMENT_SAGA" });
    }
  } catch (e) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    yield put(closeLoadingBackdrop());
  }
}

function* initCCpayment(
  { creditCardDetail, paymentMethod }: InitMMBPaymentSaga,
  order_id: string
): Generator<Effect, void, any> {
  if (!creditCardDetail) return;
  const dataExchangeResp = yield call(creditDataExchange, {
    AccountNumber: creditCardDetail.cardNumber.replace(/\s/g, ""),
  });

  if (dataExchangeResp.jwt) {
    yield put(storeCCPaymentMetaData({ creditDataExchange: dataExchangeResp }));
  }
}

function* createCCpaymentSaga(
  paymentDetail: InitMMBPaymentSaga,
  order_id: string,
  order_type: string,
  tds_md: string,
  afy_tid: string
): Generator<Effect, void, any> {
  const { creditCardDetail, paymentMethod, convenience_fee = 0, ccToken, dcc_key, dcc_accept_offer } = paymentDetail;
  if (!creditCardDetail || !ccToken) return;
  const { cardNumber, expiryDate, cardHolderName, cv2Number } = creditCardDetail;
  const nonce = v4();
  const timestamp = formatISO(new Date());
  const CCPaymentRequestBody = {
    order_id: order_id,
    device_type: "WEB",
    locale: "en_hk",
    success_url: "http://localhost:8080/success",
    failure_url: "http://localhost:8080/failure",
    payment_option: paymentMethod,
    convenience_fee,
    cc_payload: {
      dcc_key,
      dcc_accept_offer,
      card_number: encryptWithJwk(ccToken, cardNumber.replace(/\s/g, "")),
      card_expiry: encryptWithJwk(ccToken, expiryDate),
      card_cvc: encryptWithJwk(ccToken, cv2Number),
      card_holder_name: cardHolderName,
      nonce: nonce,
      timestamp: timestamp,
      sig: generateSignature(cardNumber.replace(/\s/g, ""), expiryDate, cv2Number, nonce, timestamp),
      tds_md,
      afy_tid,
    },
  };
  const rest = yield call(createCCpayment, CCPaymentRequestBody, true);
  if (rest.txn_id) {
    // yield call(retrieveOrderStatus, order_id);
    yield put({ type: "RETRIEVE_ORDER_STATUS" });
  } else {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
  }
  return rest;
}

function* createPaymentSaga(
  order_id: string,
  order_type: string,
  paymentDetail: InitMMBPaymentSaga
): Generator<Effect, void, any> {
  const { paymentMethod, convenience_fee = 0 } = paymentDetail;
  if (!paymentMethod) {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
    return;
  }
  const rest = yield call(
    createPayment,
    {
      order_id: order_id,
      device_type: "WEB",
      locale: "en_hk",
      success_url: "http://localhost:3000/en/confirmation",
      failure_url: "http://localhost:3000/en/confirmation",
      payment_option: paymentMethod,
      convenience_fee,
    } as CreatePaymentApiProps,
    true
  );
  if (rest.txn_id) {
    // yield call(retrieveOrderStatus, order_id);
    yield put({ type: "RETRIEVE_ORDER_STATUS" });
  } else {
    yield put({ type: "CANCEL_PAYMENT_SAGA" });
  }
  return rest;
}
let retryCount = 0;
let retryFirstPollingStatus = false;
function* retrieveOrderStatus(
  order_id: string,
  booking: ManageMyBookingTripDetail,
  order_type: string,
  paymentDetail?: Partial<InitMMBPaymentSaga>
): Generator<Effect, void, any> {
  while (true) {
    if (retryFirstPollingStatus) {
      yield delay(2000);
      retryFirstPollingStatus = false;
    }
    const response: GetOrderStatusResponse = yield call(getOrderStatus, order_id);
    if (response.cash_payment_status === "F_AUTH" || (response.payment_error && response.payment_error.error_code)) {
      yield put({ type: "CANCEL_PAYMENT_SAGA" });
      if (retryCount > 2) {
        RouterInstance.replace(`/:lang/agent-portal/detail?bookingRef=${booking.sales_reference}`);
        yield put(
          storeErrorModalDetail({
            type: ErrorType.M,
            title: "web.payment.paymentError3Times.error.title",
            desc: "web.payment.paymentError3Times.error.desc",
            onClose: () => {},
            data: {
              trace_id: response.metadata.trace_id,
            },
          })
        );
        break;
      } else {
        retryCount++;
        retryFirstPollingStatus = true;
        yield spawn(initPaymentSaga, {
          payload: { repay_order_id: order_id, repay_order_type: order_type, ...paymentDetail },
        });
        yield put({ type: "CREATE_PAYMENT_SAGA", order_id, order_type });
      }
    } else {
      if (response.order_status === "F_COMP") {
        yield put(
          createB2BMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id })
        );
        RouterInstance.push(`/:lang/agent-portal/detail?bookingRef=${booking.sales_reference}`);
        yield put({ type: "CANCEL_PAYMENT_SAGA" });
        break;
      } else if (response.order_status === "S_COMP") {
        // yield put(createB2BMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id }));
        yield put(
          createB2BMMBFormAction({
            form_action: response.form_action,
            form_url: response.form_value,
            order_id: order_id,
          })
        );
        // yield put(storeTripDetailSalesReference(response.sales_reference));
        RouterInstance.push("/:lang/confirmation?" + new URLSearchParams({ typeFlow: "B2B_UNSETTLE" }));
        yield put({ type: "CANCEL_PAYMENT_SAGA" });
        break;
      } else if (response.order_status === "PEND") {
        yield put(
          createB2BMMBFormAction({ form_action: response.form_action, form_url: response.form_value, order_id })
        );
      } else {
        yield put({ type: "CANCEL_PAYMENT_SAGA" });
        if (retryCount > 2) {
          RouterInstance.replace(`/:lang/agent-portal/detail?bookingRef=${booking.sales_reference}`);
          yield put(
            storeErrorModalDetail({
              type: ErrorType.M,
              title: "web.payment.paymentError3Times.error.title",
              desc: "web.payment.paymentError3Times.error.desc",
              onClose: () => {},
              data: {
                trace_id: response.metadata.trace_id,
              },
            })
          );
          break;
        } else {
          retryCount++;
          retryFirstPollingStatus = true;
          yield spawn(initPaymentSaga, {
            payload: { repay_order_id: order_id, repay_order_type: order_type, ...paymentDetail },
          });
          yield put({ type: "CREATE_PAYMENT_SAGA", order_id, order_type });
        }
      }
    }
    yield delay(5000);
  }
}
function* initPaymentSaga(action: any): Generator<Effect, void, any> {
  yield put(showLoadingBackdrop());
  let createOrderTask = undefined;
  const { booking, cash_amount, mileAmount, paymentMethod, currency, tripCost, repay_order_id, repay_order_type } =
    action.payload as InitMMBPaymentSaga;

  const pay_by_organization_credit = paymentMethod === PaymentMethod.AG ? true : false;
  if (!repay_order_id || !repay_order_type) {
    createOrderTask = yield fork(
      createOrderId,
      mileAmount,
      cash_amount,
      currency,
      tripCost,
      booking,
      pay_by_organization_credit
    );
  }

  const { order_id, order_type } = yield take("CREATE_PAYMENT_SAGA");
  if (createOrderTask) {
    yield cancel(createOrderTask);
  }
  let createPaymentTask: any;
  let orderStatusTask = undefined;

  if (order_type === "NO_PAYMENT_ORDER") {
    orderStatusTask = yield fork(retrieveOrderStatus, order_id, booking, order_type, action.payload);
  } else if (isCCMethod(paymentMethod)) {
    yield put(
      storeCCPaymentMetaData({
        orderNumber: order_id,
        amount: cash_amount,
      })
    );
    createPaymentTask = yield fork(initCCpayment, action.payload, order_id);
    const { md, afy_tid } = yield take("CREATE_CC_PAYMENT");
    yield fork(createCCpaymentSaga, action.payload, order_id, order_type, md, afy_tid);
  } else {
    createPaymentTask = yield fork(createPaymentSaga, order_id, order_type, action.payload);
  }

  if (!orderStatusTask) {
    yield take("RETRIEVE_ORDER_STATUS");
    orderStatusTask = yield fork(retrieveOrderStatus, order_id, booking, order_type, action.payload);
  }

  yield take("CANCEL_PAYMENT_SAGA");
  yield put(closeLoadingBackdrop());
  yield cancel(createOrderTask);
  yield cancel(createPaymentTask);
  yield cancel(orderStatusTask);
}

export function* B2BMMBUnsettlePaymentSaga() {
  yield takeEvery(INIT_B2B_MMB_UNSETTLE_PAYMENT_SAGA, initPaymentSaga);
}
