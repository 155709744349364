import { b2bEndPoints, endpoints, fetchAPI, getAccessTokenHeader, getApi } from "api";
import { requiredHeader } from "lib/common/service";
import {
  APICreateOrderRequestBody,
  APICreateOrderValidationRequestBody,
  AddVoucherRequestBody,
  CreatePaymentApiProps,
  CreditCMPILookupRequest,
  CreditDataExchangeRequest,
  CreditDataExchangeResponse,
  DeleteVoucherRequestBody,
  PaymentPageDetail,
  responseApiError,
} from "lib/features/flight-book/payment/definition";
import { closeLoadingBackdrop } from "modules/common/loading-backdrop/actions/LoadingBackdropAction";
import store from "store";
import { storeErrorModalDetail } from "store/sessionStorage/slices/errorModalDetail";
import { ErrorCodeList, getErrorDetail } from "./errorCodeMapping";
const forge = require("node-forge");

export const agentLogout = async () => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(b2bEndPoints.b2bAgentTokenLogout, {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
    },
    body: JSON.stringify({}),
    errorCodeWhitelist: ErrorCodeList,
  });
  return result;
};

export const orderValidation = async (data: APICreateOrderValidationRequestBody) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(b2bEndPoints.orderValidationB2B, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
    },
    body: JSON.stringify(data),
    errorCodeWhitelist: ErrorCodeList,
  }).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error);
    errorDetail && store.dispatch(storeErrorModalDetail(errorDetail));
  });
  return result;
};

export const createOrder = async (data: APICreateOrderRequestBody) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(
    b2bEndPoints.orderB2B,
    {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        ...requiredHeader(),
        ...accessTokenHeader,
      },
      body: JSON.stringify(data),
      errorCodeWhitelist: ErrorCodeList,
      captcha: window.hCaptchaConfig?.isEnable,
    },
    true
  ).catch((error: responseApiError) => {
    const errorDetail = getErrorDetail(error);
    errorDetail && store.dispatch(storeErrorModalDetail(errorDetail));
    store.dispatch(closeLoadingBackdrop());
  });
  return result;
};

export const orderOnHold = async () => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(b2bEndPoints.orderOnHoldB2B, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
    },
  });

  return result;
};

export const getOrderStatus = async (orderId: string) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(`${endpoints.getOrderStatus}/${orderId}/polling-status`, {
    method: "GET",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      ...requiredHeader(),
      ...accessTokenHeader,
    },
  });

  return result;
};

export const createPayment = async (data: CreatePaymentApiProps) => {
  const accessTokenHeader = getAccessTokenHeader();
  const result = await fetchAPI(endpoints.createPayment, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      ...accessTokenHeader,
      ...requiredHeader(),
    },
    body: JSON.stringify(data),
  });

  return result;
};
export type AddVoucherResp = {
  added_vouchers: { currency_code: string; voucher_reference_code: string; redeemed_amount: number }[];
};
export const addVoucher = async (body: AddVoucherRequestBody) => {
  const accessTokenHeader = getAccessTokenHeader();
  return await fetchAPI(endpoints.voucher, {
    method: "POST",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      ...accessTokenHeader,
    },
    body: JSON.stringify(body),
  });
};
export type DeleteVoucherResp = { remaining_vouchers: { voucher_reference_code: string; redeemed_amount: number }[] };
export const deleteVoucher = async (body: DeleteVoucherRequestBody): Promise<DeleteVoucherResp> => {
  const accessTokenHeader = getAccessTokenHeader();
  return await fetchAPI(endpoints.voucher, {
    method: "PUT",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      ...accessTokenHeader,
    },
    body: JSON.stringify(body),
  });
};
export type CCEncryptionToken = {
  kid: string;
  alg: string;
  kty: string;
  use: string;
  n: string;
  e: string;
};

export const getCCEncryptionToken = async (): Promise<CCEncryptionToken> => {
  return await getApi(endpoints.endpointPaymentHost + "/v1/payment/key-discovery");
};
export const createCCpayment = async (body: any) => {
  const accessTokenHeader = getAccessTokenHeader();
  return await fetchAPI(endpoints.endpointPaymentHost + "/external/v1/payment/create-payment", {
    method: "POST",
    cache: "no-store",
    headers: {
      "Content-Type": "application/json",
      ...accessTokenHeader,
    },
    body: JSON.stringify(body),
  });
};
export const encryptCCPayment = (data: PaymentPageDetail, token: CCEncryptionToken) => {};

export function encryptWithJwk(jwk: CCEncryptionToken, data: any) {
  data = forge.util.createBuffer(data, "utf8");

  // turn jwk.n and jwk.e from base64url to base64
  const n = base64url_to_hex(jwk.n);
  const e = base64url_to_hex(jwk.e);

  const publicKey = forge.rsa.setPublicKey(new forge.jsbn.BigInteger(n, 16), new forge.jsbn.BigInteger(e, 16));

  const encrypted = publicKey.encrypt(data.bytes(), "RSA-OAEP", {
    md: forge.md.sha256.create(),
    mgf1: {
      md: forge.md.sha1.create(),
    },
  });

  return forge.util.encode64(encrypted);
}

function base64url_to_base64(input: string) {
  // Replace non-url compatible chars with base64 standard chars
  input = input.replace(/-/g, "+").replace(/_/g, "/");

  // Pad out with standard base64 required padding characters
  const pad = input.length % 4;
  if (pad) {
    if (pad === 1) {
      throw new Error("InvalidLengthError: Input base64url string is the wrong length to determine padding");
    }
    input += new Array(5 - pad).join("=");
  }

  return input;
}

function base64url_to_hex(x: string) {
  const b64 = base64url_to_base64(x);
  return forge.util.createBuffer(atob(b64), "raw").toHex();
}

export function generateSignature(cn: string, ce: string, cc: string, nonce: string, timestamp: string) {
  const md = forge.md.sha256.create();
  const md2 = forge.md.sha256.create();
  md2.update(cn + ce + cc + nonce + timestamp);
  md.update(md2.digest().toHex());
  return md.digest().toHex();
}

export const creditDataExchange = async (data: CreditDataExchangeRequest): Promise<CreditDataExchangeResponse> => {
  const result = await fetchAPI(`${endpoints.endpointPaymentHost}/v1/cardinal/data-exchange-api-get-info`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return result;
};

export type creditCMPILookupResp = {
  step_up_url: string;
  three_dsversion: string;
  md: string;
  jwt: string;
};
export const getCreditCMPILookup = async (data: CreditCMPILookupRequest | any): Promise<creditCMPILookupResp> => {
  const result = await fetchAPI(`${endpoints.endpointPaymentHost}/v1/cardinal/cmpi-look-up`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(data),
  });
  return result;
};
