import {
  Journey,
  MMBPassengersDetail,
  ManageMyBookingSearchingDetail,
  Pax,
  TripDetail,
} from "@hkexpressairwayslimited/ui";
import { createAction } from "@reduxjs/toolkit";
import { LongPollingFormAction, PaymentOption } from "lib/features/flight-book/payment/definition";
import { ManageMyBookingTripDetail } from "lib/features/manage-my-booking/my-trips/definition";
import {
  InitMMBChangeFlightPaymentSaga,
  InitMMBPaymentSaga,
} from "lib/features/manage-my-booking/my-trips/payment/definition";
import { OnBaggageSelectedData, OnMealSelectedData } from "ui/features/flight-booking/add-extras";
import {
  OnSimCardSelectedData,
  OnSportSelectedData,
  OnUFirstSelectedData,
} from "ui/features/flight-booking/add-extras/OthersSection";
import { ChangeFlightAddExtrasPageMetaData, SelectedArItems, TripDetailPageMetaData } from "./reducers";

const namespace = "MANAGE_MY_BOOKING";

export const STORE_MANAGE_MY_BOOKING_FLIGHT_SEARCHING = `${namespace}/STORE_MANAGE_MY_BOOKING_FLIGHT_SEARCHING`;
export const storeManageMyBookingFlightSearching = createAction<ManageMyBookingSearchingDetail>(
  STORE_MANAGE_MY_BOOKING_FLIGHT_SEARCHING
);

export const STORE_TRIPS = `${namespace}/STORE_TRIPS`;
export const storeTrips = createAction<ManageMyBookingTripDetail[]>(STORE_TRIPS);

export const ADD_TRIPS = `${namespace}/ADD_TRIPS`;
export const addTrips = createAction<ManageMyBookingTripDetail>(ADD_TRIPS);

export const UPDATE_TRIPS = `${namespace}/UPDATE_TRIPS`;
export const updateTrips = createAction<ManageMyBookingTripDetail>(UPDATE_TRIPS);

export const STORE_PASSENGER_DETAIL = `${namespace}/STORE_PASSENGER_DETAIL`;
export const storePassengerDetail = createAction<{ tripId?: string; data: MMBPassengersDetail }>(
  STORE_PASSENGER_DETAIL
);

export const CLEAN_PASSENGER_DETAIL = `${namespace}/CLEAN_PASSENGER_DETAIL`;
export const cleanPassengerDetail = createAction<{ tripId: string }>(CLEAN_PASSENGER_DETAIL);

export const UPDATE_PAX_MEAL = `${namespace}/UPDATE_PAX_MEAL`;
export const updatePaxMeal = createAction<OnMealSelectedData>(UPDATE_PAX_MEAL);
export const CONFIRM_PAX_MEAL = `${namespace}/CONFIRM_PAX_MEAL`;
export const confirmPaxMeal = createAction<SelectedArItems[]>(CONFIRM_PAX_MEAL);

export const UPDATE_U_FIRST = `${namespace}/UPDATE_U_FIRST`;
export const updateUFirst = createAction<OnUFirstSelectedData>(UPDATE_U_FIRST);
export const CONFIRM_U_FIRST = `${namespace}/CONFIRM_U_FIRST`;
export const confirmUFirst = createAction<SelectedArItems[]>(CONFIRM_U_FIRST);

export const UPDATE_SIM_CARD = `${namespace}/UPDATE_SIM_CARD`;
export const updateSIMCard = createAction<OnSimCardSelectedData>(UPDATE_SIM_CARD);
export const CONFIRM_SIM_CARD = `${namespace}/CONFIRM_SIM_CARD`;
export const confirmSIMCard = createAction<SelectedArItems[]>(CONFIRM_SIM_CARD);
export const UPDATE_INSURANCE = `${namespace}/UPDATE_INSURANCE`;
export const updateInsurance = createAction<{ tripId: string; passengers: Pax[]; added?: number; code: string }>(
  UPDATE_INSURANCE
);

export const RESET_CHANGE_FLIGHT_STATE = `${namespace}/RESET_CHANGE_FLIGHT_STATE`;
export const resetChangeFlightState = createAction(RESET_CHANGE_FLIGHT_STATE);

export const UPDATE_CARBON_OFFSET = `${namespace}/UPDATE_CARBON_OFFSET`;
export const updateCarbonOffset = createAction<{
  tripId: string;
  passengers: Pax[];
  added: boolean;
  code: string;
}>(UPDATE_CARBON_OFFSET);

export const UPDATE_SPORT = `${namespace}/UPDATE_SPORT`;
export const updateSport = createAction<OnSportSelectedData>(UPDATE_SPORT);
export const CONFIRM_SPORT = `${namespace}/CONFIRM_SPORT`;
export const confirmSport = createAction<SelectedArItems[]>(CONFIRM_SPORT);

export const CONFIRM_BAGGAGE = `${namespace}/CONFIRM_BAGGAGE`;
export const confirmBaggage = createAction<SelectedArItems[]>(CONFIRM_BAGGAGE);
export const UPDATE_BAGGAGE = `${namespace}/UPDATE_BAGGAGE`;
export const updateBaggage = createAction<OnBaggageSelectedData>(UPDATE_BAGGAGE);

export const UPDATE_TRIP_DETAIL_META_DATA = `${namespace}/UPDATE_ADD_EXTRAS_PAGE_META_DATA`;
export const updateTripDetailPageMetaData = createAction<Partial<TripDetailPageMetaData>>(UPDATE_TRIP_DETAIL_META_DATA);

export const UPDATE_STEPPER_INDEX = `${namespace}/UPDATE_STEPPER_INDEX`;
export const updateStepperActive = createAction<number>(UPDATE_STEPPER_INDEX);

export const UPDATE_PASSENGER_RESULT = `${namespace}/UPDATE_PASSENGER_RESULT`;
export const updatePassengerResultActive = createAction<boolean>(UPDATE_PASSENGER_RESULT);

export const MMB_NSK_TOKEN_EXPIRE_NOTIFY = `${namespace}/NSK_TOKEN_EXPIRE_NOTIFY`;
export const mmbNskTokenExpireNotify = createAction<boolean>(MMB_NSK_TOKEN_EXPIRE_NOTIFY);

export const RESET_MMB_STATE = `${namespace}/RESET_MMB_STATE`;
export const resetMMBState = createAction(RESET_MMB_STATE);

export const STORE_CHANGE_FLIGHT_TRIP_DETAIL = `${namespace}/STORE_CHANGE_FLIGHT_TRIP_DETAIL`;
export const storeChangeFlightTripDetail = createAction<TripDetail>(STORE_CHANGE_FLIGHT_TRIP_DETAIL);

export const REMOVE_CHANGE_FLIGHT_TRIP_DETAIL = `${namespace}/REMOVE_CHANGE_FLIGHT_TRIP_DETAIL`;
export const removeChangeFlightTripDetail = createAction<number>(REMOVE_CHANGE_FLIGHT_TRIP_DETAIL);

export const CONFIRM_CHANGE_FLIGHT_SELECTED_BAGGAGE = `${namespace}/CONFIRM_CHANGE_FLIGHT_SELECTED_BAGGAGE`;
export const confirmChangeFlightSelectedBaggage = createAction<SelectedArItems[]>(
  CONFIRM_CHANGE_FLIGHT_SELECTED_BAGGAGE
);

export const UPDATE_CHANGE_FLIGHT_BAGGAGE = `${namespace}/UPDATE_CHANGE_FLIGHT_BAGGAGE`;
export const updateChangeFlightBaggage = createAction<OnBaggageSelectedData>(UPDATE_CHANGE_FLIGHT_BAGGAGE);

export const UPDATE_CHANGE_FLIGHT_ADD_EXTRAS_PAGE_META_DATA = `${namespace}/UPDATE_CHANGE_FLIGHT_ADD_EXTRAS_PAGE_META_DATA`;
export const updateChangeFlightAddExtrasPageMetaData = createAction<Partial<ChangeFlightAddExtrasPageMetaData>>(
  UPDATE_CHANGE_FLIGHT_ADD_EXTRAS_PAGE_META_DATA
);

export const UPDATE_CHANGE_FLIGHT_PAX_MEAL = `${namespace}/UPDATE_CHANGE_FLIGHT_PAX_MEAL`;
export const updateChangeFlightPaxMeal = createAction<OnMealSelectedData>(UPDATE_CHANGE_FLIGHT_PAX_MEAL);

export const CONFIRM_CHANGE_FLIGHT_PAX_MEAL = `${namespace}/CONFIRM_CHANGE_FLIGHT_PAX_MEAL`;
export const confirmChangeFlightPaxMeal = createAction<SelectedArItems[] | undefined>(CONFIRM_CHANGE_FLIGHT_PAX_MEAL);

export const UPDATE_CHANGE_FLIGHT_SPORT = `${namespace}/UPDATE_CHANGE_FLIGHT_SPORT`;
export const updateChangeFlightSport = createAction<OnSportSelectedData>(UPDATE_CHANGE_FLIGHT_SPORT);
export const CONFIRM_CHANGE_FLIGHT_SPORT = `${namespace}/CONFIRM_CHANGE_FLIGHT_SPORT`;
export const confirmChangeFlightSport = createAction<SelectedArItems[]>(CONFIRM_CHANGE_FLIGHT_SPORT);

export const UPDATE_CHANGE_FLIGHT_U_FIRST = `${namespace}/UPDATE_CHANGE_FLIGHT_U_FIRST`;
export const updateChangeFlightUFirst = createAction<OnUFirstSelectedData>(UPDATE_CHANGE_FLIGHT_U_FIRST);
export const CONFIRM_CHANGE_FLIGHT_U_FIRST = `${namespace}/CONFIRM_CHANGE_FLIGHT_U_FIRST`;
export const confirmChangeFlightUFirst = createAction<SelectedArItems[]>(CONFIRM_CHANGE_FLIGHT_U_FIRST);

export const UPDATE_CHANGE_FLIGHT_SIM_CARD = `${namespace}/UPDATE_CHANGE_FLIGHT_SIM_CARD`;
export const updateChangeFlightSIMCard = createAction<OnSimCardSelectedData>(UPDATE_CHANGE_FLIGHT_SIM_CARD);
export const CONFIRM_CHANGE_FLIGHT_SIM_CARD = `${namespace}/CONFIRM_CHANGE_FLIGHT_SIM_CARD`;
export const confirmChangeFlightSIMCard = createAction<SelectedArItems[]>(CONFIRM_CHANGE_FLIGHT_SIM_CARD);

export const PUSH_MMB_JOURNEY = `${namespace}/PUSH_MMB_JOURNEY`;
export const pushMMBJourney = createAction<Journey>(PUSH_MMB_JOURNEY);

export const INIT_MMB_PAYMENT_SAGA = `${namespace}/INIT_MMB_PAYMENT_SAGA`;
export const initMMBPaymentSaga = createAction<InitMMBPaymentSaga>(INIT_MMB_PAYMENT_SAGA);

export const INIT_MMB_UNSETTLE_PAYMENT_SAGA = `${namespace}/INIT_MMB_UNSETTLE_PAYMENT_SAGA`;
export const initMMBUnsettlePaymentSaga = createAction<Partial<InitMMBPaymentSaga>>(INIT_MMB_UNSETTLE_PAYMENT_SAGA);

export const INIT_MMB_CHANGE_FLIGHT_PAYMENT_SAGA = `${namespace}/INIT_MMB_CHANGE_FLIGHT_PAYMENT_SAGA`;
export const initMMBChangeFlightPaymentSaga = createAction<Partial<InitMMBChangeFlightPaymentSaga>>(
  INIT_MMB_CHANGE_FLIGHT_PAYMENT_SAGA
);

export const CREATE_MMB_FORM_ACTION = `${namespace}/CREATE_FORM_ACTION`;
export const createMMBFormAction = createAction<LongPollingFormAction>(CREATE_MMB_FORM_ACTION);

export const STORE_MMB_PAYMENT_OPTION = `${namespace}/STORE_MMB_PAYMENT_OPTION`;
export const storeMMBPaymentOption = createAction<PaymentOption[]>(STORE_MMB_PAYMENT_OPTION);
