import {
  ArItem,
  ArItems,
  Button,
  ButtonVariant,
  CheckFilledSvg,
  EBorders,
  EFont,
  ESpacing,
  ETripType,
  ExpressBoardingSvg,
  Font,
  IBEPax,
  Image,
  Journey,
  PlaneSvg,
  PriorityCheckInSvg,
  Tag,
  TickCircleIcon,
  TripDetail,
  UFirstIcon,
  useMediaQueries,
} from "@hkexpressairwayslimited/ui";
import { Box, Stack, SvgIcon } from "@mui/material";
import { getTripTitle } from "lib/features/flight-book/helper";
import { useTransContent } from "modules/common/trans-content/transContent";
import { calcAllPickedArItems } from "modules/features/flightBooking/calculateCost/calcAllPickedArItems";
import { getStoredJourneyArItems, useJourneyCost } from "modules/features/flightBooking/calculateCost/useJourneyCost";
import { SelectedArItems } from "modules/features/flightBooking/reducers";
import { useCurrency } from "modules/global/currency/useCurrency";
import { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  selectAddExtrasPageMetaData,
  updateAddExtrasPageMetaData,
} from "store/sessionStorage/slices/flightBookingSlice";
import {
  OnUFirstCloseData,
  OnUFirstSelectedData,
  SelectItem,
  Summary,
  formatJourneySelectedAndPurchasedArItemSummary,
} from ".";
import { EArItemType, EOtherArCode } from "..";
import { Drawer } from "../../Drawer";
type UFirstDrawerProps = {
  open: boolean;
  onClose: () => void;
  onConfirm: () => void;
  confirmLabel?: string;
  journeys: Journey[];
  tripType?: ETripType;
  passengers: IBEPax[];
  totalCost: string | JSX.Element;
  onUFirstAdded: (added: boolean, journey_key: string, code: string) => void;
  autoClose?: boolean;
  selectedArItem?: SelectedArItems[];
};
function JourneyUFirstSelector({
  onClick,
  title,
  journey,
  selectedArItem,
}: {
  onClick: (added: boolean, code: string) => void;
  title: string;
  journey: Journey;
  selectedArItem?: SelectedArItems;
}) {
  const uFirstConf = useMemo(() => {
    const selectAmount =
      selectedArItem?.arItems.reduce((a: number, c: ArItem) => {
        if (c.byPax) {
          return a + c.byPax.reduce((a1, c1) => a1 + c1.amount, 0);
        }
        return a;
      }, 0) ?? 0;

    const {
      availability = 0,
      price = 0,
      code,
    } = journey.arItems.available.find((e: ArItem) => e?.type === EArItemType.U_FIRST) ?? {};
    return {
      selectAmount,
      data: {
        code,
        noStock: (availability ?? 0) === 0,
        unavailable: availability !== null || availability === 0,
        totalCost: (selectAmount * price).toString(),
        price: price.toString(),
        selected: !!selectedArItem?.arItems.find((e: ArItem) => e.type === EArItemType.U_FIRST),
        purchased: !!journey.arItems.purchased.find((e) => e.type === EArItemType.U_FIRST),
      },
    };
  }, [selectedArItem?.arItems, journey.arItems.available, journey.arItems.purchased]);

  const [added, setAdded] = useState(uFirstConf?.data.selected ?? false);
  const { t } = useTransContent();
  const { P } = useCurrency();
  const { isMobile } = useMediaQueries();

  if (uFirstConf && uFirstConf.data) {
    const { price, purchased, unavailable, code = "" } = uFirstConf.data;
    const addedBackgroundObj = !(purchased || (unavailable && !added)) && added ? { backgroundColor: "#E2D5E9" } : {};
    return (
      <Stack
        sx={{ paddingY: ESpacing._sm, borderBottom: EBorders.b1 }}
        direction={["column", "column", "row"]}
        justifyContent='space-between'
        alignItems='center'
      >
        <Box>
          <Font color='purple.default'>{title}</Font>
          <Stack direction='row' spacing={ESpacing._2xs} alignItems='center'>
            <Font variant={EFont.h3} fontWeight='fontWeightBold' sx={{ fontSize: "18px" }}>
              {t(`airportCodeToCityName.${journey.origin}`)} {t("web.home.bookATrip.to")}{" "}
              {t(`airportCodeToCityName.${journey.destination}`)}
            </Font>
          </Stack>
        </Box>
        {journey.fare.bundle_offers.map((e) => (
          <Tag
            key={e.bundle_code}
            variant='promo_message'
            value={e.type ? t(`${e.type}.title`) : "****"}
            sx={{ maxHeight: "32px" }}
          />
        ))}
        <Stack direction='row' justifyContent='space-between' alignItems='center' spacing={ESpacing._sm}>
          <Stack direction='row' columnGap={2} alignItems='center'>
            <Font variant={EFont.p1} color='purple.default' fontWeight='fontWeightBold'>
              {P(price)}
            </Font>
            <Font>{t("web.flightBooking.otherSection.prePax")}</Font>
          </Stack>
          <Button
            style={{ minWidth: isMobile ? "none" : "96px", ...addedBackgroundObj }}
            disabled={purchased || (unavailable && !added)}
            onClick={() => {
              if (purchased || (unavailable && !added)) return;
              setAdded(!added);
              onClick && onClick(added, code);
            }}
            variant={ButtonVariant.Secondary}
          >
            {added || purchased
              ? t("web.flightBooking.otherSection.button.added")
              : t("web.flightBooking.otherSection.button.add")}
          </Button>
        </Stack>
      </Stack>
    );
  }
}
export function UFirstSection({
  code,
  passengers,
  tripDetail,
  tripType,
  price,
  selectedUFirstArItem,
  onUFirstConfirmed,
  onUFirstSelected,
  onOpenUFirstDrawer,
  onClose,
}: {
  code: EOtherArCode;
  price?: string | JSX.Element;
  passengers: IBEPax[];
  tripDetail: TripDetail;
  tripType: ETripType;
  selectedUFirstArItem: SelectedArItems[];
  onUFirstConfirmed?: (b: boolean) => void;
  onUFirstSelected: (data: OnUFirstSelectedData) => void;
  onClose?: (data: OnUFirstCloseData) => void;
  onOpenUFirstDrawer?: () => void;
}) {
  const dispatch = useDispatch();
  const metaData = useSelector(selectAddExtrasPageMetaData)?.confirmedItems;
  const [confirmed, setConfirmed] = useState(metaData ? metaData[EArItemType.U_FIRST] ?? false : false);
  const [open, setOpen] = useState(false);
  const uFirstCost = useJourneyCost(EArItemType.U_FIRST, getStoredJourneyArItems(tripDetail.journeys));
  const selectedUFirstCost = calcAllPickedArItems(
    selectedUFirstArItem?.reduce((a: ArItems[], c) => {
      a.push({
        available: tripDetail.journeys.find((e) => e.journey_key === c.journeyKey)?.arItems.available ?? [],
        selected: c.arItems ?? [],
        purchased: [],
      });
      return a;
    }, []),
    undefined,
    true
  );
  useEffect(() => {
    if (confirmed === true) {
      dispatch(
        updateAddExtrasPageMetaData({
          confirmedItems: {
            [EArItemType.U_FIRST]: true,
          },
        })
      );
      setOpen(false);
    }
  }, [confirmed, dispatch]);
  const handleJourneyUFirstClicked = useCallback(
    (added: boolean, journeyKey: string, code: string) => {
      onUFirstSelected({
        tripId: tripDetail.id,
        journeyKey,
        added,
        passengers,
        code,
      });
    },
    [onUFirstSelected, passengers, tripDetail.id]
  );
  const { P } = useCurrency();
  const isAllPurchased = tripDetail.journeys.every((journey) =>
    journey.arItems.purchased.find((e) => e.type === EArItemType.U_FIRST)
  );
  const isSomePurchased = tripDetail.journeys.some((journey) =>
    journey.arItems.purchased.find((e) => e.type === EArItemType.U_FIRST)
  );
  return (
    <SelectItem
      totalCost={P(uFirstCost)}
      code={code}
      price={price}
      onClick={() => {
        setOpen(true);
        onOpenUFirstDrawer && onOpenUFirstDrawer();
      }}
      summary={
        confirmed || isSomePurchased ? (
          <UFirstSummary journeys={tripDetail.journeys} tripType={tripType} passengers={passengers} />
        ) : undefined
      }
      isAllPurchased={isAllPurchased}
    >
      <UFirstDrawer
        autoClose={true}
        onConfirm={() => {
          setConfirmed(true);
          setOpen(false);
          onUFirstConfirmed && onUFirstConfirmed(true);
        }}
        passengers={passengers}
        journeys={tripDetail.journeys}
        tripType={tripType}
        open={open}
        onClose={() => {
          onClose && onClose({ tripId: tripDetail.id, code });
          setOpen(false);
        }}
        totalCost={P(selectedUFirstCost, undefined, false, 0, true)}
        onUFirstAdded={handleJourneyUFirstClicked}
        selectedArItem={selectedUFirstArItem}
      />
    </SelectItem>
  );
}
export function UFirstDrawer({
  open,
  onClose,
  journeys,
  tripType,
  passengers,
  onConfirm,
  totalCost,
  onUFirstAdded,
  confirmLabel,
  autoClose = false,
  selectedArItem,
}: UFirstDrawerProps) {
  const { t } = useTransContent();
  return (
    <Drawer
      autoClose={autoClose}
      title={`${t("web.addEXtras.UFirstDrawer.header.title")}`}
      open={open}
      onClose={onClose}
      onConfirm={onConfirm}
      avatar={<UFirstIcon />}
      totalCost={totalCost}
      confirmLabel={t("web.manageMyBooking.myTrips.addItems.btn")}
      crossIconClose={true}
    >
      <Stack spacing={ESpacing._sm}>
        <Font>{t("web.addEXtras.UFirstDrawer.header")}</Font>
        <Stack direction='row' spacing={ESpacing._xs}>
          <Stack alignItems='center' spacing={ESpacing._xs} sx={{ minWidth: "160px", position: "relative" }}>
            <Box sx={{ position: "relative", display: ["none", "none", "flex"] }}>
              <SvgIcon sx={{ fontSize: "100px" }}>
                <PriorityCheckInSvg />
              </SvgIcon>
              <SvgIcon sx={{ fontSize: "28px", position: "absolute", top: 0, right: 0 }}>
                <CheckFilledSvg />
              </SvgIcon>
            </Box>
            <Box sx={{ display: ["block", "block", "none"] }}>
              <Image alt='' src={t("web.addExtras.UFirstDrawer.priority.image") as string} width='100' height='100' />
            </Box>
            <Font fontWeight='fontWeightBold'>{t("web.addEXtras.UFirstDrawer.priority")}</Font>
          </Stack>
          <Stack alignItems='center' spacing={ESpacing._xs} sx={{ minWidth: "160px" }}>
            <Box sx={{ position: "relative", display: ["none", "none", "flex"] }}>
              <SvgIcon sx={{ fontSize: "100px" }}>
                <ExpressBoardingSvg />
              </SvgIcon>
              <SvgIcon sx={{ fontSize: "28px", position: "absolute", top: 0, right: 0 }}>
                <CheckFilledSvg />
              </SvgIcon>
            </Box>
            <Box sx={{ display: ["block", "block", "none"] }}>
              <Image alt='' src={t("web.addExtras.UFirstDrawer.express.image") as string} width='100' height='100' />
            </Box>
            <Font fontWeight='fontWeightBold'>{t("web.addEXtras.UFirstDrawer.express")}</Font>
          </Stack>
        </Stack>
        <Box>
          {journeys.map((journey, idx) => (
            <JourneyUFirstSelector
              key={journey.journey_key}
              journey={journey}
              title={
                t(
                  `web.flightBook.flightSelect.flightDetailPopUp.${getTripTitle(journey.index ?? 0, tripType)}`
                ) as string
              }
              onClick={(added, code) => {
                onUFirstAdded(added, journey.journey_key, code);
              }}
              selectedArItem={selectedArItem?.find((e) => e.journeyKey === journey.journey_key)}
            />
          ))}
        </Box>
        <Font>{t("web.addExtras.UFirstDrawer.bottom")}</Font>
      </Stack>
    </Drawer>
  );
}
export function UFirstSummary({
  journeys,
  passengers,
  tripType,
}: {
  journeys: Journey[];
  passengers: IBEPax[];
  tripType: ETripType;
}) {
  const JourneyArItemSummary = formatJourneySelectedAndPurchasedArItemSummary(
    journeys,
    EArItemType.U_FIRST,
    tripType,
    passengers
  );
  const { t } = useTransContent();
  return (
    <Summary>
      <Stack direction='row' spacing={ESpacing._2xs}>
        <SvgIcon>
          <TickCircleIcon />
        </SvgIcon>
        <Stack spacing={ESpacing._2xs} width='100%'>
          <Font color='purple.default'>{t("web.addEXtras.UFirstSummary.add")}</Font>
          {JourneyArItemSummary.map((e) => (
            <Stack key={e.label} direction='row' justifyContent='space-between'>
              <Stack direction='row' spacing={ESpacing._2xs}>
                <SvgIcon sx={{ color: "neutral.black" }}>
                  <PlaneSvg />
                </SvgIcon>
                <Font fontWeight='fontWeightBold'>{t(`web.flightBook.flightSelect.flightDetailPopUp.${e.label}`)}</Font>
              </Stack>
              <Font>X{e.amount}</Font>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Summary>
  );
}
